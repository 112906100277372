<template>
  <sdPageHeader title="Add campaign default values" />
  <GeneralForm :formData="form" :initialData="initialData" :otherFormData="otherForm">
    <template v-slot:content-setting>
      <div class="create-account-form">
        <a-row :gutter="15" style="margin-bottom: 0;">
          <a-col :lg="24" :xs="24" style="margin-bottom: 26px">
            <sdHeading as="h4">Step 1 Details</sdHeading>
            <a-row :gutter="25" style="margin-bottom: 0;">
              <a-col :lg="12" :xs="24">
                <a-form-item name="step1_title" label="Title">
                  <a-input v-model:value="form.step1_title" placeholder="Enter Title" />
                </a-form-item>
              </a-col>
              <a-col :lg="12" :xs="24">
                <a-form-item name="step1_button_text" label="Button Text">
                  <a-input v-model:value="form.step1_button_text" placeholder="Enter button text" />
                </a-form-item>
              </a-col>
              <a-col :lg="24" :xs="24">
                <a-form-item name="step1_description" label="Description">
                  <a-textarea v-model:value="form.step1_description" :rows="4" placeholder="Enter description" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
          <a-col :lg="24" :xs="24">
            <sdHeading as="h4">Spinner Details</sdHeading>
            <a-row :gutter="25" style="margin-bottom: 0;">
              <a-col :lg="12" :xs="24" style="margin-bottom: 26px;">
                  <a-form-item name="spinner_title" label="Title">
                    <a-input  v-model:value="form.spinner_title" placeholder="Enter Title" />
                  </a-form-item>
                </a-col>
              <a-col :lg="12" :xs="24" style="margin-bottom: 26px;">
                <a-form-item name="spinner_button_text" label="Button Text">
                  <a-input v-model:value="form.spinner_button_text" placeholder="Enter Title" />
                </a-form-item>
              </a-col>
              <a-col :lg="24" :xs="24" style="margin-bottom: 26px;">
                  <a-form-item name="spinner_description" label="Description">
                    <a-textarea v-model:value="form.spinner_description" :rows="4" placeholder="Enter description" />
                  </a-form-item>
                </a-col>
              <a-col :lg="6" :xs="24">
                <a-form-item name="spinner_front_image" label="Spinner Front Image">
                  <a-input
                    type="file"
                    id="card-front-file"
                    accept="image/*"
                    v-on:change="onImageChange($event, 'spinner_front_image')"
                  />
                </a-form-item>
                <div v-if="spinner_front_image_url" class="img-thumb-cls">
                  <img alt="Spinner Front Image" :src="spinner_front_image_url"/>
                </div>
              </a-col>
              <a-col :lg="6" :xs="24">
                <a-form-item name="spinner_center_image" label="Spinner Center Image">
                  <a-input
                    type="file"
                    id="card-back-file"
                    accept="image/*"
                    v-on:change="onImageChange($event, 'spinner_center_image')"
                  />
                </a-form-item>
                <div v-if="spinner_center_image_url" class="img-thumb-cls">
                  <img alt="Spinner Center Image" :src="spinner_center_image_url"/>
                </div>
              </a-col>
              <a-col :lg="6" :xs="24">
                <a-form-item name="bg_image" label="Campaign Background Image">
                  <a-input
                    type="file"
                    accept="image/*"
                    id="bg-image-file"
                    v-on:change="onImageChange($event, 'bg_image')"
                  />
                </a-form-item>
                <div v-if="bg_image_url" class="img-thumb-cls">
                  <img alt="Background Image" :src="bg_image_url"/>
                </div>
              </a-col>
              <a-col :lg="6" :xs="24">
                  <a-form-item name="loser_popup_image" label="Loser Popup Image">
                    <a-input
                      type="file"
                      id="card-back-file"
                      accept="image/*"
                      v-on:change="onImageChange($event, 'loser_popup_image')"
                    />
                  </a-form-item>
                  <div v-if="loser_popup_url" class="img-thumb-cls">
                    <img alt="Loser Popup Image" :src="loser_popup_url"/>
                  </div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col style="margin-top: 30px;" :lg="12" :xs="24">
            <sdHeading as="h4">Success details</sdHeading>
            <a-form-item name="success_title" label="Title">
              <a-input v-model:value="form.success_title" placeholder="Enter Title" />
            </a-form-item>
            <a-form-item name="success_btn_title" label="Button Title">
              <a-input v-model:value="form.success_btn_title" placeholder="Enter success button title" />
            </a-form-item>
            <!-- <a-form-item name="success_url" label="Button URL">
              <a-input type="url" v-model:value="form.success_url" placeholder="Enter success button url" />
              <small>[firstname]&nbsp;&nbsp;[lastname]&nbsp;&nbsp;[email]&nbsp;&nbsp;[city]&nbsp;&nbsp;[phone]&nbsp;&nbsp;[zipcode]&nbsp;&nbsp;[address]</small>
            </a-form-item> -->
            <a-form-item name="success_description" label="Description">
              <a-textarea v-model:value="form.success_description" :rows="4" placeholder="Enter description" />
            </a-form-item>
          </a-col>
          <a-col style="margin-top: 30px;" :lg="12" :xs="24">
            <sdHeading as="h4">Failure details</sdHeading>
            <a-form-item name="fail_title" label="Title">
              <a-input v-model:value="form.fail_title" placeholder="Enter Title" />
            </a-form-item>
            <a-form-item name="fail_btn_title" label="Button Title">
              <a-input v-model:value="form.fail_btn_title" placeholder="Enter failure button title" />
            </a-form-item>
            <a-form-item name="fail_url" label="Button URL">
              <a-input type="url" v-model:value="form.fail_url" placeholder="Enter failure button url" />
              <small>[firstname]&nbsp;&nbsp;[lastname]&nbsp;&nbsp;[email]&nbsp;&nbsp;[city]&nbsp;&nbsp;[phone]&nbsp;&nbsp;[zip]&nbsp;&nbsp;[address]</small>
            </a-form-item>
            <a-form-item name="fail_description" label="Description">
              <a-textarea v-model:value="form.fail_description" :rows="4" placeholder="Enter description" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="15">
            <a-col :lg="24" :xs="24">
                <sdHeading as="h4">Prize Block</sdHeading>
                <a-row :gutter="25" style="margin-top: 15px;">
                  <a-col :lg="12" :xs="24">
                    <a-checkbox
                      name="prize_popup_status"
                      v-model:checked="form.prize_popup_status"
                    >
                      <strong>Active</strong>
                    </a-checkbox>
                  </a-col>
                </a-row>
                <a-row :gutter="25" style="margin-bottom: 0;">
                  <a-col :lg="12" :xs="24">
                    <a-form-item name="prize_block_title" label="Popup Title">
                      <a-input  v-model:value="form.prize_block_title" placeholder="Enter Title" />
                    </a-form-item>
                  </a-col>
                  <a-col :lg="12" :xs="24">
                    <a-form-item name="prize_button_text" label="Prize Button Text">
                      <a-input  v-model:value="form.prize_button_text" placeholder="Enter button text" />
                    </a-form-item>
                  </a-col>
              </a-row>
            </a-col>
          </a-row>
          <a-row :gutter="15">
            <a-col :lg="24" :xs="24">
                <sdHeading as="h4">Close Detail</sdHeading>
                <a-row :gutter="25" style="margin-bottom: 0;">
                  <a-col :lg="24" :xs="24">
                    <a-form-item name="close_redirect_url" label="Redirect URL">
                      <a-input  v-model:value="form.close_redirect_url" placeholder="Enter Redirect URL" />
                    </a-form-item>
                  </a-col>
                  <a-col :lg="24" :xs="24" style="margin-top: 15px;">
                    <a-form-item name="close_message" label="Message">
                      <a-textarea v-model:value="form.close_message" :rows="4" placeholder="Enter Message" />
                    </a-form-item>
                  </a-col>
              </a-row>
            </a-col>
          </a-row>
      </div>
    </template>
    <template v-slot:winners-prizes>
      <sdHeading as="h4">Wheel fields</sdHeading>
      <a-row>
        <a-col :lg="24" :xs="24">
          <a-form-item name="spinner_pin_color" label="Pin Color">
            <a-input v-model:value="form.spinner_pin_color" placeholder="Enter pin color" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="25" v-for="(spnr, index) in form.spinner" :key="`row_${index}`" class="seprates">
        <a-col :lg="8" :xs="24">
          <a-form-item :name="['spinner', index, 'title']">
            <a-input v-model:value="spnr.title" placeholder="Enter Spinner Title" />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :xs="24">
          <a-form-item :name="['spinner', index, 'prize_url']">
            <a-input v-model:value="spnr.prize_url" placeholder="Enter Spinner URL" />
          </a-form-item>
        </a-col>
        <a-col :lg="4" :xs="24" class="custom_more_btn">
          <a-button type="dashed" class="dynamic-delete-button" style="width: 95%" v-if="index > 0" @click="removeSpinnerBlock(index)">
            <MinusOutlined /> Remove Block
          </a-button>
          <a-button type="dashed" style="width: 95%" v-else-if="index == 0" @click="addSpinnerBlock">
            <PlusOutlined />Add Block
          </a-button>
        </a-col>
        <a-col :lg="5" :xs="24" class="custom_select_selector" style="margin-top:10px">
            <a-form-item :name="['spinner', index, 'prize']">
            <a-select
              show-search
              placeholder="Select prize"
              option-filter-prop="label"
              v-model:value="spnr.prize"
            >
              <a-select-option value="0">No prize</a-select-option>
              <a-select-option
                v-for="prize of prizes"
                :key="prize.id"
                :value="prize.id"
                >{{ prize.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="5" :xs="24" style="margin-top:10px">
          <a-form-item :name="['spinner', index, 'weight']">
            <a-input type="number" v-model:value="spnr.weight" placeholder="Enter Weight" />
          </a-form-item>
        </a-col>
        <a-col :lg="5" :xs="24">
          <a-form-item :name="['spinner', index, 'text_color']" style="margin-top:10px">
            <a-input v-model:value="spnr.text_color" placeholder="Enter Text Color" />
          </a-form-item>
        </a-col>
        <a-col :lg="5" :xs="24">
          <a-form-item :name="['spinner', index, 'background_color']" style="margin-top:10px">
            <a-input v-model:value="spnr.background_color" placeholder="Enter Background Color" />
          </a-form-item>
        </a-col>
        
      </a-row>
    </template>
  </GeneralForm>
</template>

<script>
import _ from "lodash";
import VueTypes from 'vue-types';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons-vue';

import GeneralForm from "@/components/generalComponent/admin/themeDefaultForm.vue";

import { API } from "@/services/api";
import Notification from "@/services/Notification";
export default {
  name: "SetupCampaign",
  components: {
    GeneralForm,
    PlusOutlined,
    MinusOutlined,
  },
  props: {
    defaultData: VueTypes.object.def({}),
  },
  data() {
    return {
      initialData: {},
      form: {
        step1_title: "",
        step1_button_text: "",
        step1_description: "",
        spinner_button_text: "",
        spinner_title: "",
        spinner_description: "",
        success_title: "",
        success_url: "",
        success_btn_title: "",
        success_description: "",
        fail_title: "",
        fail_url: "",
        fail_btn_title: "",
        fail_description: "",
        prize_block_title: "",
        prize_button_text: "",
        prize_popup_status: true,
        bg_image: null,
        spinner_front_image: null,
        spinner_center_image: null,
        spinner: [],
        spinner_pin_color: "",
        loser_popup_image: null,
        close_redirect_url: "",
        close_message: "",
      },
      otherForm: {
        meta_image_url: null,
        campaign_image_url: null,
        selectedOs: [],
        selectedDevice: [],
        selectedBrowser: [],
        selectedCountry: [],
      },
      bg_image_url: "",
      spinner_front_image_url: "",
      spinner_center_image_url: "",
      prizes: [],
      loser_popup_url: "",
    };
  },
  computed: {
    countries: function() {
      return this.$store.getters.countries;
    },
  },
  methods: {
    onImageChange(e, type) {
      const file = e?.target?.files[0];
      const blobURL = URL.createObjectURL(file);
      if (type == "bg_image") {
        this.bg_image_url = blobURL;
        this.form.bg_image = file;
        return null;
      }
      if(type == "spinner_front_image") {
        this.spinner_front_image_url = blobURL;
        this.form.spinner_front_image = file;
        return null;
      }
      if(type == "spinner_center_image") {
        this.spinner_center_image_url = blobURL;
        this.form.spinner_center_image = file;
        return null;
      }
    },
    getValue(value) {
      if (value == null) {
        return ""
      } else if (_.isString(value)) {
        if (value == "true" || value == "false") {
          return JSON.parse(value);
        }
        return value;
      } else if (_.isNumber(value) || _.isArray(value)) {
        return value;
      } else {
        return JSON.stringify(value);
      }
    },
    spinnerCount() {
      this.form.spinner.push({
        title: "",
        prize_url: "",
        prize : "",
        weight: "",
        text_color: "",
        background_color: "",
      });
    },
    addSpinnerBlock() {
      this.spinnerCount();
    },
    removeSpinnerBlock(value) {
      this.form.spinner.splice(value, 1);
    },
    async getPrizes() {
      try {
        const datRes = await API.getPrizes();
        const { data: sData, status, message } = datRes.data;
        if (!status && message) {
          Notification.show(message, {type: "error",title: "Error"});
        } else {
          this.prizes = sData.prizes;
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, {type: "error",title: "Error"});
        }
      } finally {
        this.fetching = false;
      }
    },
  },
  mounted() {
    if (_.isObject(this.defaultData) && !_.isEmpty(this.defaultData)) {
      const newFormData = {};
      const newInitialData = {};

      for (const [key, value] of Object.entries(this.defaultData)) {
        if (key && value) {
          if (key.includes("flag")) {
            if (!_.has(this.form, key)) {
              newInitialData[key] = value && !!JSON.parse(value);
            } else {
              newFormData[key] = value && !!JSON.parse(value);
            }
          } else {
            if (!_.has(this.form, key)) {
              newInitialData[key] = this.getValue(value);
            } else {
              newFormData[key] = this.getValue(value);
            }
          }
        }
      }
      this.initialData = {
        ...this.initialData,
        ...newInitialData,
      };
      this.form = {
        ...this.form,
        ...newFormData,
      };
      const {
        bg_image = "", spinner_front_image = "", spinner_center_image = "", loser_popup_image = "",
        os = "", device = "", browser = "", country = "",script_country = [], script_device = [],
        meta_image = null, campaign_image = null , spinner = [],
      } = this.defaultData;
      this.otherForm.meta_image_url = meta_image;
      this.otherForm.campaign_image_url = campaign_image;
      this.bg_image_url = bg_image;
      this.spinner_front_image_url = spinner_front_image;
      this.spinner_center_image_url = spinner_center_image;
      this.loser_popup_url = loser_popup_image;
      if (!_.isEmpty(os)) {
        this.otherForm.selectedOs = os;
      }
      if (!_.isEmpty(device)) {
        this.otherForm.selectedDevice = device;
      }
      if (!_.isEmpty(browser)) {
        this.otherForm.selectedBrowser = browser;
      }
      if (!_.isEmpty(country)) {
        this.otherForm.selectedCountry = _.map(this.countries, (c) => country.includes(c.value) && c.value).filter(c => c);
      }
      if (!_.isEmpty(script_country)) {
        this.otherForm.scriptSelectedCountry = script_country;
      }
      if (!_.isEmpty(script_device)) {
        this.otherForm.scriptSelectedDevice = script_device;
      }
      if (spinner.length > 0) {
        if (_.isString(spinner)) {
          this.form.spinner = JSON.parse(spinner);
        } else {
          this.form.spinner = spinner;
        }
      }
    }
  },
  created() {
    this.getPrizes();
    this.spinnerCount();
  },
}
</script>